import * as React from "react"
import SlideUp from "./SlideUp/SlideUp";
import ContactFormik from "./Formik/ContactForm/ContactFormik";
import HomepageBackground from "./HomepageBackground/HomepageBackground";

const Layout = ({children}) => (
  <div className={'page-wrapper'}>
    <main className={'main'}>{children}</main>
    <HomepageBackground tag={'aside'} className={'aside'}></HomepageBackground>
    <SlideUp
        name={'Get in touch'}>
      <ContactFormik></ContactFormik>
    </SlideUp>
    <footer className={'footer'}> © {new Date().getFullYear()} | Coldstream Management LLC</footer>
    <div className="credits"><a href='https://www.freepik.com/vectors/blue-polygon'>Blue polygon vector created by Harryarts - www.freepik.com</a></div>
  </div>
);

export default Layout;
