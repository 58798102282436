import React, { useState } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import './contact-form.scss';

const formSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string()
    .email("Invalid email")
    .required("Required"),
  message: Yup.string().required("Required")
});

const ContactFormik = () => {

  const FORM_ID = 'xjvjvgyg';

  /* Server State Handling */
  const [serverState, setServerState] = useState();

  const handleServerResponse = (ok, msg) => {
    setServerState({ok, msg});
  };

  const handleOnSubmit = (values, actions) => {
    axios({
      method: "POST",
      url: `https://formspree.io/${FORM_ID}`,
      data: values
    })
      .then(response => {
        actions.setSubmitting(false);
        actions.resetForm();
        handleServerResponse(true, "Thanks!");
      })
      .catch(error => {
        actions.setSubmitting(false);
        const errorMsg = error.response ? error.response.data.error : 'Network Error';
        handleServerResponse(false, errorMsg);
      });
  };

  let contactFormClasses = '';
  contactFormClasses += (serverState && !serverState.ok) ? 'contact-form--error': '';
  contactFormClasses += (serverState && serverState.ok) ? 'contact-form--success': '';

  const showSuccess = (serverState && serverState.ok);
  const showError = (serverState && !serverState.ok);

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          email: "",
          message: ""
        }}
        onSubmit={handleOnSubmit}
        validationSchema={formSchema}
      >
        {({ isSubmitting }) => (
          <Form noValidate className={`contact-form ${contactFormClasses}`}>

            {/*{serverState && (*/}
            {/*  <div className={!serverState.ok ? " contact-form__error" : ""}>*/}
            {/*    <span className={'form__error'}>Something went wrong! Please email us directly at <a*/}
            {/*      href="mailto:info@coldstream-mgmt.com">info@coldstream-mgmt.com</a>.</span>*/}
            {/*  </div>*/}
            {/*)}*/}

            <div className={'form__item contact-form__name'}>
              <label className={`form__label`}
                     htmlFor="name">Name</label>
              <ErrorMessage name="name" className="form__error" component="span" />
              <Field id="name"
                     className={`form__input`}
                     type="text"
                     name="name" />
            </div>

            <div className={'form__item contact-form__email'}>
              <label className={`form__label`}
                     htmlFor="email">Email</label>
              <ErrorMessage name="email" className="form__error" component="span" />
              <Field id="email"
                     className={`form__input`}
                     type="email"
                     name="email" />
            </div>

            <div className={'form__item contact-form__message'}>
              <label className={`form__label`}
                     htmlFor="message">Message</label>
              <ErrorMessage name="message" className="form__error" component="span" />
              <Field id="message"
                     className={`form__input`}
                     name="message"
                     component="textarea" />
            </div>

            <div className={'contact-form__submit form__controls'}>
              <button className={'btn--submit'} type="submit" disabled={isSubmitting}>Send</button>
            </div>

            { showError && (
              <div aria-live={'polite'} className={"contact-form__error"}>
                <span className={'form__error'}>Something went wrong! Please email us directly at <a
                  href="mailto:info@coldstream-mgmt.com">info@coldstream-mgmt.com</a>.</span>
              </div>
            )}

            { showSuccess && (
              <div aria-live={'polite'} className={"contact-form__success"}>
                <p>Thanks for getting in touch, we'll be in touch as soon as possible!</p>
                <p>If you can't wait, please feel free to reach out to <br/>
                  <a
                  href="mailto:info@coldstream-mgmt.com">info@coldstream-mgmt.com</a>.</p>
              </div>
            )}

            {/*<div className="g-recaptcha" data-sitekey="6Ld-BswaAAAAAC508gJqYDwe5p9KGAxo9PastIKc"></div>*/}
            {/*<script src="https://www.google.com/recaptcha/api.js" async defer></script>*/}

          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactFormik;
